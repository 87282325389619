<template>
  <div class="partnerlist patnerWarp">
<!--    <van-search v-model="searchVal" placeholder="搜索" @input="getPartnerList"/>-->
    <van-checkbox-group v-model="result" class="content">
      <van-checkbox checked-color="#3F7C53"  :name="item.userName" v-for="(item, index) in partnerList" :key="index">{{item.userName}}</van-checkbox>
    </van-checkbox-group>
    <div class="publicBtnDiv">
      <van-button class="publicBtnActive" slot="button" type="info" color="#3F7C53" @click="buttonClick">完成</van-button>
    </div>
  </div>
</template>

<script>
import { Icon, Button, Search, Checkbox, CheckboxGroup, Toast  } from 'vant'
import { getParterByDeptNo } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  components:{
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Search.name]: Search,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
  },
  props:['deptNo','peoplesstr'],
  data() {
    return {
      searchVal: '',
      result: [],
      partnerList:[],
    }
  },
  methods: {
    buttonClick() {
      if(this.result.length > 2){
        Toast('参与人员最多2个，请重新选择')
        return
      }
      this.$emit('overlayClose', this.result)
    },
    getPartnerList() {
      getParterByDeptNo({
        token: common.getItem('wtToken'),
        deptNo: this.deptNo,
        userName: this.searchVal
      }).then(res=>{
        if(res.code === 200){
          if(res.data && res.data.businessCode === 200){
            if(res.data.data.parterList){
              this.partnerList = res.data.data.parterList
            }else{
              this.partnerList = []
            }
          }
        }
      })
    }
  },
  mounted() {
    if(this.peoplesstr){
      this.result = this.peoplesstr.split(',')
    }
    this.getPartnerList()
    uwStatisticAction('/site/partnerlist','选择参与人员')
  }
}
</script>

<style lang="less" scoped>
.partnerlist{
  height: 100%;
  padding-bottom: 50px;
  padding-top: 12px;
  background: #f5f5f5;
  overflow-y: auto;
  overflow-x: hidden;
  .van-overlay{
    z-index: 120;
  }
  .content{
    .van-checkbox{
      height: 1rem;
      padding: 0 0.3rem;
      border-bottom: 0.01rem solid #ddd;
      background: #fff;
    }
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .van-button{
      width: 100%;
    }
  }
}
</style>
<style>
  .patnerWarp .van-checkbox__label{

    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
</style>
